.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

.mainWidth {
    width: 1200px;
    overflow: hidden;
    height: 624px;
    position: absolute;
    z-index: 1;
    margin-left: -600px;
    top: 0;
    left: 50%;
}

.logo {
    height: 50px;
    vertical-align: middle;
}

.loginTelIcon {
    vertical-align: middle;
    margin-right: 10px;
}

.loginBackground {
    z-index: 0;
    width: 100%;
    min-width: 1400px;
    min-height: 624px;
}

.main {
    /*height: 526px;*/
    flex: 1;
    position: relative;
    min-width: 1200px;
}

.mainLeft {
    height: 100%;
    width: 463px;
    float: left;
    background: url('./styles/img/loginImg.png') no-repeat 50px center;
}

.mainRight {
    width: 350px;
    height: 460px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
}

.loginForm {
    width: 258px;
    margin: 40px auto 0 auto;
    position: relative;
}

.loginFormLine {
    position: absolute;
    right: 0;
    top: 0;
}

.resetPwdR .ant-row {
    margin-bottom: 10px;
}

.resetPwdR > p {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 32px 0;
    border-bottom: 1px solid #f1f1f1;
    position: relative;
    text-align: center;
}

.resetPwdR h3 a {
    font-size: 12px;
    position: absolute;
    right: 10px;
    top: 10px;
    text-decoration: underline;
}

.resetPwdR .ant-row {
    margin-bottom: 10px;
}

:global .register .ant-input {
    height: 38px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
